
    import { defineComponent } from 'vue';
    import { chevronForwardSharp } from 'ionicons/icons';
    import { GeneralApiClient } from '../../generated';
    import { store } from '../../store/store';
    import {
        IonItem,
        IonLabel,
        IonButton,
        IonList,
        IonIcon
    } from '@ionic/vue';
    import { SharedMixin } from '../../mixins/SharedMixin';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'Privacy',
        mixins: [SharedMixin, LanguageMixin],
        components: {
            IonItem,
            IonLabel,
            IonButton,
            IonList,
            IonIcon
        },
        data() {
            return {
                privacyLink: '',
                termsandconditionslink: '',
                showPrivacy: true
            }
        },
        setup() {
            return {
                chevronForwardSharp
            }
        },
        methods: {
            Approve: function () {
                this.setCookie('privacy-accepted', "true");
                this.showPrivacy = false;
            },
        },
        mounted() {
            const generalApiClient = new GeneralApiClient();
            generalApiClient.getGeneralConditionsLink(store.state.currentLanguage).then(
                result => {
                    this.termsandconditionslink = result ?? '';
                });

            generalApiClient.getPrivacyLink(store.state.currentLanguage).then(
                result => {
                    this.privacyLink = result ?? '';
                });
        }
    });